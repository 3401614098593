import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate} from 'react-router-dom';


import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

export default function P2AReceiver() {

    const [rows, setRows] = React.useState([]);

    const navigate = useNavigate();

    React.useEffect(() => {
        const fetchData = async () => {
            try {
                let url = 'https://smsauth.messagemate.at/goip_api/listReceivers.php';
                const response = await fetch(url);
                if (response.ok) {
                    const data = await response.json();
                    setRows(data.data);
                } else {
                    console.error('Failed to fetch data:', response.statusText);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
    
      fetchData();
    }, []);

    const handleEdit = (id) => {
        navigate(`/p2a/receiver-edit/${id}`);
    };

    const handleDelete = (id) => {
        fetch(`https://smsauth.messagemate.at/goip_api/delReceiver.php?receiverid=${id}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ id })
        })
        .then(response => response.json())
        .then(data => {
            console.log(data)
            if (data.success === true) {
                const newRows = rows.filter(row => row.id !== id);
                setRows(newRows);
            }
        });
    };


    function DelButtonModal({ id }) {
        const [open, setOpen] = React.useState(false);
        const theme = useTheme();
        const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
        
        const handleClickOpen = () => {
            setOpen(true);
        };
        
        const handleClose = () => {
            setOpen(false);
        };

        function combinedClickHandler() {
            handleClose();
            handleDelete(id);
        }
      
    
        return (
            <div>
            <Button variant="outlined" size="small" color="error" onClick={handleClickOpen}>
                Delete
            </Button>
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    {"Are you sure?"}
                </DialogTitle>
                <DialogActions>
                <Button autoFocus onClick={handleClose}>
                    Disagree
                </Button>
                <Button onClick={combinedClickHandler} autoFocus>
                    Agree
                </Button>
                </DialogActions>
            </Dialog>
            </div>
        );
    }
  
    const columns = [
        { field: 'no', headerName: 'No', width: 160 },
        { field: 'name', headerName: 'Name', width: 160 },
        { field: 'number', headerName: 'Number', type: 'number', width: 160 },
        { field: 'groupid', headerName: 'Group ID', type: 'number', width: 60 },
        { field: 'groupname', headerName: 'Groupname', width: 100 },
        { field: 'providerid', headerName: 'Provider ID', type: 'number', width: 40 },
        { field: 'provider', headerName: 'Providername', width: 60 },
        { field: 'info', headerName: 'Info', width: 160 },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 180,
            renderCell: (params) => {
                return (
                <>
                    <Button size="small" variant="outlined" style={{marginRight: 15}} onClick={() => handleEdit(params.row.id)}>Edit</Button>
                    <DelButtonModal id={params.row.id} />
                </>
                );
            }
        }
    ];
  
  
    return (
        <div style={{ height: 631, width: '100%' }}>
          <DataGrid
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
              },
            }}
            pageSizeOptions={[10, 20, 50]}
            // checkboxSelection
          />
        </div>
      );
  };