import * as React from 'react';
import { useEffect, useState, useCallback  } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import ButtonsGroup from "../buttonsGroup/ButtonsGroup";
import SmsCounter from "../smsCounter/SmsCounter";

export default function DashboardSid({ isAuthenticated }) {
  const [fetchedData, setFetchedData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activeButton, setActiveButton] = useState(1);
  const url = 'https://smsauth.messagemate.at/api/listSidStatistics.php';

  const fetchData = useCallback(async (id) => {
    const baseURL = `https://smsauth.messagemate.at/api/listSidStatistics.php?timespankey=${id}`;

    try {
      const response = await fetch(baseURL, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error(`Server responded with status: ${response.status}`);
      }

      const data = await response.json();
      if (data['errorcode'] !== "200") {
        console.error(data['error']);
      } else {
        setFetchedData(data.data);
      }
    } catch (error) {
      console.error("Error fetching SMS:", error);
    }
  }, [url]);

  useEffect(() => {
    fetchData(1);

    const interval = setInterval(() => fetchData(1), 5 * 60 * 1000);

    return () => clearInterval(interval);
  }, [fetchData]);

  const rows = fetchedData.map((row, index) => {
    return { id: index, ...row };
  });

  const columns = [
    { field: 'sid', headerName: 'SID', width: 220 },
    { field: 'received', headerName: 'Received', type: 'number', width: 120 },
    { field: 'namesList', headerName: 'Pages', width: 740 },
  ]

  return (
    <div style={{ height: 631, width: '100%' }}>
      <SmsCounter/>
      <ButtonsGroup
        setFetchedData={setFetchedData}
        setLoading={setLoading}
        setActiveButton={setActiveButton}
        url={url}
      />
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        pageSizeOptions={[10, 20, 50]}
      />
    </div>
  );
}
