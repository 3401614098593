import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';

export default function DropdownList({ refreshData, listApiUrl, addApiUrl, headerText, inputText }) {
    const [items, setItems] = useState([]);
    const [selectedItem, setSelectedItem] = useState('');

    useEffect(() => {
        fetch(listApiUrl)
            .then(response => response.json())
            .then(data => {
                if (data && data.data) {
                    setItems(data.data);
                }
            })
            .catch(error => {
                console.error('Error fetching data: ', error);
            });
    }, [listApiUrl]);

    const handleChange = (event) => {
        setSelectedItem(event.target.value);
    };

    function addToMaster() {
        fetch(`${addApiUrl}?id=${selectedItem}`)
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                console.log('success');
                refreshData();
                }
            })
            .catch(error => {
                console.error('Error fetching data: ', error);
            });
    }

    return (
        <div className='dropdown__container'>
            <h2 className='batch-header'>{headerText}</h2>
            <Box className="dropdown__box" sx={{ minWidth: 340 }}>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">ADD TO {inputText} RUN LIST</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedItem}
                        label="Name (SID)"
                        onChange={handleChange}
                    >
                        {items.map((item) => (
                            <MenuItem key={item.id} value={item.id}>{`${item.name} (${item.sid})`}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
            <Button className="dropdown__button" variant="outlined" onClick={addToMaster}>ADD</Button>
        </div>
    );
}
