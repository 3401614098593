import React, { useState, useEffect } from 'react';
import { FormControl, InputLabel, Select, MenuItem, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import './P2ASendCampaign.scss';

const P2ASendCampaign = ({ onChange }) => {
    const [groups, setGroups] = useState([]);
    const [selectedId, setSelectedId] = useState('');

    const navigate = useNavigate();

    useEffect(() => {
        const fetchGroups = async () => {
        try {
            const response = await fetch('https://smsauth.messagemate.at/goip_api/listCampaigns.php');
            const data = await response.json();
            if (data && data.data) {
            setGroups(data.data);
            }
        } catch (error) {
            console.error('Error while fetching data:', error);
        }
        }; 

        fetchGroups();
    }, []);

    const handleChange = (event) => {
        setSelectedId(event.target.value);
    };

    const handleGoClick = async () => {
        try {
        const response = await fetch(`https://smsauth.messagemate.at/goip_api/getCampaign.php?id=${selectedId}`);
        const data = await response.json();
        const campaignData = data.data;
    
        if (campaignData && campaignData.campaign.campaigntype === 'qd') {
            navigate('/p2a/send-goip', { state: { campaignData } });
        } else if (campaignData && campaignData.campaign.campaigntype === 'bulk') {
            navigate('/p2a/send-direct', { state: { campaignData } });
        }
        } catch (error) {
            console.error('Error while fetching campaign data:', error);
        }
    };
    

    return (
        <div>
        <FormControl sx={{ width: 234, marginRight: 1 }}>
            <InputLabel id="group-select-label">Select Campaign</InputLabel>
            <Select
            labelId="group-select-label"
            id="group-select"
            label="Select Campaign"
            value={selectedId}
            onChange={handleChange}
            >
            {groups.map((group) => (
                <MenuItem key={group.id} value={group.id}>
                {group.campaignname}
                </MenuItem>
            ))}
            </Select>
        </FormControl>
            <div className="reports__btn-wrapper">
                <Button className="reports__btn" variant="outlined" onClick={handleGoClick}>Go</Button>
            </div>
        </div>
    );
};

export default P2ASendCampaign;
