import React, { useState, useEffect } from 'react';
import { FormControl, InputLabel, Select, MenuItem, FormHelperText } from '@mui/material';

const SendDirectMessageDropdown = ({ onChange, error, helperText }) => {

    const [messages, setMessages] = useState([]);
    const [selectedMessage, setSelectedMessage] = useState('');

    useEffect(() => {
        const fetchMessages = async () => {
        try {
            const response = await fetch('https://smsauth.messagemate.at/goip_api/listMessages.php');
            const data = await response.json();
            if (data && data.data) {
                setMessages(data.data);
            }
        } catch (error) {
            console.error('Error while fetching data:', error);
        }
        };

        fetchMessages();
    }, []);

    const handleChange = (event) => {
        const selectedId = event.target.value;
        const selectedMessage = messages.find(msg => msg.id === selectedId);
        
        setSelectedMessage(selectedId);

        if (onChange) {
        onChange({ id: selectedId, text: selectedMessage?.message });
        }
    };

    return (
        <FormControl sx={{ width: 685 }} error={error}>
        <InputLabel id="message-select-label">Choose message template</InputLabel>
        <Select
            labelId="message-select-label"
            id="message-select"
            value={selectedMessage}
            label="Choose message template"
            onChange={handleChange}
        >
            {messages.map((message) => (
            <MenuItem key={message.id} value={message.id}>
                {message.message}
            </MenuItem>
            ))}
        </Select>
        {error && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
    );
};

export default SendDirectMessageDropdown;