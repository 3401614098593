import React, { useEffect, useState } from 'react';
import { LinearProgress, Box, Typography, Button } from '@mui/material';


const fetchData = async (setCampaigns) => {
    try {
        const response = await fetch('https://smsauth.messagemate.at/goip_api/showRunningCampaigns.php');
        if (response.ok) {
            const data = await response.json();
            setCampaigns(data.data);
        } else {
            console.error('Failed to fetch data:', response.statusText);
        }
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};

const killCampaign = async (id, setCampaigns) => {
    try {
        const response = await fetch(`https://smsauth.messagemate.at/goip_api/killCampaign.php?run_id=${id}`);
        const result = await response.json();

        if (result.errorcode === '200') {
            setCampaigns(prevCampaigns => prevCampaigns.filter(campaign => campaign.ref_run_id !== id));
        } else {
            console.error("Error killing the campaign: ", result.error);
        }

    } catch (error) {
        console.error("There was an error fetching the data", error);
    }
};

const RunningCampaigns = () => {
    const [campaigns, setCampaigns] = useState([]);

    useEffect(() => {
        fetchData(setCampaigns);
    }, []);

    return (
        <div>
            <h2>Running Campaigns</h2>
            <Box sx={{ width: '100%', marginTop: '20px' }}>
            {campaigns.map((campaign) => (
                <Box key={campaign.id} sx={{ mb: 2, display: 'flex', alignItems: 'center', gap: 2, marginBottom: '30px' }}>
                    <Typography variant="body1" sx={{ width: '15%', flexShrink: 0 }}>
                        {campaign.campaignname}
                    </Typography>
                    <Box sx={{ width: '100%', position: 'relative', display: 'flex', alignItems: 'center', gap: 1 }}>
                        <LinearProgress variant="determinate" value={(campaign.jobs_left / campaign.jobs) * 100} sx={{ height: 20, width: '100%' }}/>
                        <Typography variant="body2" sx={{ position: 'absolute', right: 0, top: '-20px' }}>
                        {`${campaign.jobs - campaign.jobs_left}/${campaign.jobs}`}
                        </Typography>
                    </Box>
                    <Button variant="outlined" onClick={() => killCampaign(campaign.ref_run_id, setCampaigns)}>KILL</Button>
                </Box>
            ))}
            </Box>
        </div>
    );
};

export default RunningCampaigns;
