import React from 'react';
import { Box, Input } from '@mui/material';



function SendDirectScvInput({ onValidCsv }) {
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            console.log("File type: ", file.type);
            
            const reader = new FileReader();
            reader.onload = function(e) {
                const text = e.target.result;
                const allNumbers = text.split('\n').map(line => line.trim()).filter(line => line);
                onValidCsv(allNumbers);
            };
            reader.readAsText(file);
        }
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '56px', marginLeft: '30px' }}>
            <Input 
                type="file"
                onChange={handleFileChange}
                inputProps={{ accept: '.csv' }}
                sx={{height: '56px'}}
            />
        </Box>
    );
}

export default SendDirectScvInput;