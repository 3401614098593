import React, { useEffect, useState } from 'react';
import './SmsCounterP2A.scss';

export default function SmsCounterP2A() {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchData = async () => {
        try {
            const response = await fetch('https://smsauth.messagemate.at/goip_api/countSMS.php');
            const result = await response.json();

            if (result.errorcode === '200'){
            setData(result.data);
            setIsLoading(false);
            } else {
            setError(data['error_message']);
            }

        } catch (error) {
        setError(error.message);
        setIsLoading(false);
        console.error("There was an error fetching the data", error);
        }
    };

    useEffect(() => {
        fetchData();
        const interval = setInterval(fetchData, 5 * 60 * 1000);

        return () => clearInterval(interval);
    }, []);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div className="counter__wrapper">
        {data.map((item, index) => (
            <table className="counter__table" key={index}>
            <thead>
                <tr>
                <th>OUT</th>
                <th>{item.period.toUpperCase()}</th>
                <th>IN</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                <td>{item.smsOut}</td>
                <td></td>
                <td>{item.smsIn}</td>
                </tr>
            </tbody>
            </table>
        ))}
        </div>
    );
}
