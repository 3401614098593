import React, { useState, useEffect } from 'react';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
// import { useLocation } from 'react-router-dom';
import { useParams, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import '../batchAdd/BatchAdd.scss';




export default function BatchEdit() {
    const [sid, setSid] = useState("");
    const [name, setName] = useState("");
    const [remark, setRemark] = useState("");
    const [url, setUrl] = useState("");
    const [script, setScript] = useState("");
    const [isActive, setIsActive] = useState(true);
    const { id } = useParams();
    const user = Cookies.get('username');
    const navigate = useNavigate();

    const [errors, setErrors] = useState({});

    // Textfield validate
    const validateRequiredText = (value) => {
        return value.trim() ? "" : "This field is required.";
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`https://smsauth.messagemate.at/api/getSid.php?id=${id}`);
                const result = await response.json();

                if (result.errorcode === '200'){
                    const data = result.data;

                    setSid(data.sid);
                    setName(data.name);
                    setRemark(data.remark);
                    setUrl(data.url);
                    setScript(data.script);
                    setIsActive(data.isActive);
                }



            } catch (error) {
                console.error("There was an error fetching the data", error);
            }
        };

        fetchData();
        // eslint-disable-next-line
    }, [id]);
  

    const handleSubmit = async () => {

        const validationErrors = {
            sid: validateRequiredText(sid),
            name: validateRequiredText(name),
            url: validateRequiredText(url),
            script: validateRequiredText(script)
        };

        const hasErrors = Object.values(validationErrors).some(error => error);
    
        if (hasErrors) {
            setErrors(validationErrors);
            return;
        }

        setErrors({});

        const formData = new FormData();
        formData.append('id', id);
        formData.append('sid', sid);
        formData.append('name', name);
        formData.append('remark', remark);
        formData.append('url', url);
        formData.append('script', script);
        formData.append('is_active', isActive);
        formData.append('user', user);

        console.log(formData);
    
        try {
            const response = await fetch('https://smsauth.messagemate.at/api/editBatchlist.php', {
                method: 'POST',
                body: formData,
            })
            .then(response => response.json())
            .then(data => {
              console.log(data);
              if (data.success) {
                navigate('/batch-list');
              } else {
                // Some logic
              }
            })
            
            const result = await response.json();
            console.log(result);
        } catch (error) {
            console.error("There was an error sending the data", error);
        }
    };
  
    return (
        <Stack>
                <h2 className='header'>Batch List EDIT</h2>
                <div className='inputs-wrapper'>
                    <TextField 
                        error={!!errors.sid}
                        helperText={errors.sid}
                        id="sid" 
                        label="SID" 
                        variant="outlined" 
                        value={sid} 
                        required 
                        onChange={(e) => setSid(e.target.value)} 
                    />
                </div>
                <div className='inputs-wrapper'>
                    <TextField 
                        error={!!errors.name}
                        helperText={errors.name}
                        id="name" 
                        label="NAME" 
                        variant="outlined" 
                        value={name} 
                        required 
                        onChange={(e) => setName(e.target.value)} 
                    />
                </div>
                <div className='inputs-wrapper'>
                    <TextField 
                        multiline 
                        id="remark" 
                        label="REMARK" 
                        variant="outlined" 
                        value={remark} 
                        onChange={(e) => setRemark(e.target.value)} 
                    />
                </div>
                <div className='inputs-wrapper'>
                    <TextField 
                        error={!!errors.url}
                        helperText={errors.url}
                        id="url" 
                        label="URL" 
                        variant="outlined" 
                        value={url} 
                        required 
                        onChange={(e) => setUrl(e.target.value)} 
                    />
                </div>
                <div className='inputs-wrapper'>
                    <TextField 
                        error={!!errors.script}
                        helperText={errors.script}
                        id="script" 
                        label="SCRIPT" 
                        variant="outlined" 
                        value={script} 
                        required 
                        onChange={(e) => setScript(e.target.value)} 
                    />
                </div>
                <div className='inputs-wrapper'>
                    <FormControlLabel 
                        control={
                            <Checkbox 
                                checked={isActive} 
                                value={isActive} 
                                onChange={() => setIsActive(!isActive)} 
                            />
                        } 
                        label="active" 
                    />
                </div>
                <div className='inputs-wrapper'>
                    <Button variant="outlined" onClick={handleSubmit}>save</Button>
                </div>
        </Stack>
    );
}


