import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import SwitchControlled from '../switch/Switch';
import Cookies from 'js-cookie';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import './BatchRun.scss';

import BatchDropdownList from "../batchDropdownList/BatchDropdownList";
import '../batchDropdownList/BatchDropdownList.scss';

export default function BatchRun({
    listApiUrl = 'https://smsauth.messagemate.at/api/listSid.php?active=1',
    startApiUrl = 'https://smsauth.messagemate.at/api/startSid.php',
    stopApiUrl = 'https://smsauth.messagemate.at/api/stopSid.php',
    header = 'Master Run',
    dropdownApiUrl="https://smsauth.messagemate.at/api/listForMaster.php",
    deleteApiUrl = 'https://smsauth.messagemate.at/api/removeFromMaster.php',
    addApiUrl = 'https://smsauth.messagemate.at/api/addToMaster.php',
    inputText = 'MASTER'
}) {
    const [rows, setRows] = React.useState([]);

    const fetchData = async () => {
        try {
        const response = await fetch(listApiUrl);
        if (response.ok) {
            const data = await response.json();
            setRows(data.data);
        } else {
            console.error('Failed to fetch data:', response.statusText);
        }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
  
    React.useEffect(() => {
        fetchData();
        // eslint-disable-next-line
    }, [listApiUrl]);


    const user = Cookies.get('username');
    const role = Cookies.get('role');

    const handleToggle = async (id, isChecked) => {
        const baseApiUrl = isChecked ? startApiUrl : stopApiUrl;
        const apiUrl = `${baseApiUrl}?id=${id}&user=${user}`;

    const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ id })
    });

    const data = await response.json();

    if (data.success) {
        console.log(data);
        const updatedRows = rows.map(row => {
            if (row.id === id) {
            return { ...row, is_running: isChecked ? '1' : '0', remark: data.remark };
            }
            return row;
        });
        setRows(updatedRows);
        } else {
            console.error(`Error ${data.errorcode}: ${data.errormessage}`);
        }
  };

    const batchDelete = (id) => {
        fetch(`${deleteApiUrl}?id=${id}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ id })
        })
        .then(response => response.json())
        .then(data => {
        if (data.success) {
            fetchData();
        }
        })
        .catch(error => console.error('Error:', error));
    };

    function DelButtonModal({ id }) {
        const [open, setOpen] = React.useState(false);
        const theme = useTheme();
        const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  
        const handleClickOpen = () => {
            setOpen(true);
        };
    
        const handleClose = () => {
            setOpen(false);
        };

        function combinedClickHandler() {
            handleClose();
            batchDelete(id);
        }
  
    
  
        return (
            <div>
                <Button variant="outlined" size="small" color="error" onClick={handleClickOpen}>
                Delete
                </Button>
                <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
                >
                <DialogTitle id="responsive-dialog-title">
                    {"Are you sure?"}
                </DialogTitle>
                <DialogActions>
                    <Button autoFocus onClick={handleClose}>
                    Disagree
                    </Button>
                    <Button onClick={combinedClickHandler} autoFocus>
                    Agree
                    </Button>
                </DialogActions>
                </Dialog>
            </div>
        );
    }

    const columns = [
        { field: 'sid', headerName: 'SID', width: 200 },
        { field: 'name', headerName: 'Name', width: 200 },
        { field: 'is_running', headerName: 'Running', type: 'number', width: 100 },
        { field: 'remark', headerName: 'Remark', width: 300 },
        {
        field: 'actions',
        headerName: 'Start/Stop',
        width: 180,
        renderCell: (params) => {
            const isRunning = params.row.is_running === '1';
            const isDisabled = role !== 'admin';
            return (
            <SwitchControlled
                isChecked={isRunning}
                disabled={isDisabled}
                onToggle={(newChecked) => handleToggle(params.id, newChecked)}
            />
            );
        }
        },
        {
        field: 'delete',
        headerName: 'Delete',
        width: 96,
        renderCell: (params) => {
            return (
            <>
                <DelButtonModal id={params.row.id} />
            </>
            );
        }
        }
    ];

    return (
        <div style={{ height: 631, width: '100%' }}>
            <BatchDropdownList headerText={header} refreshData={fetchData} listApiUrl={dropdownApiUrl} addApiUrl={addApiUrl} inputText={inputText}/>
            <DataGrid
                rows={rows}
                columns={columns}
                initialState={{
                pagination: {
                    paginationModel: { page: 0, pageSize: 10 },
                },
                }}
                pageSizeOptions={[10, 20, 50]}
            />
        </div>
    );
}
