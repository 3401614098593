import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
// import SwitchControlled from '../switch/Switch';
import Cookies from 'js-cookie';
import './Servers.scss'


export default function Servers({
    listApiUrl = 'https://smsauth.messagemate.at/api/checkActivity.php',
    startApiUrl = 'https://smsauth.messagemate.at/api/startApi.php',
    stopApiUrl = 'https://smsauth.messagemate.at/api/stopApi.php'
}) {
    const [rows, setRows] = React.useState([]);

    const fetchData = async () => {
        try {
            // Fetch activity
            const response = await fetch(listApiUrl);
            if (response.ok) {
                const data = await response.json();
                setRows(data.data);
            } else {
                console.error('Failed to fetch data:', response.statusText);
            }

        } catch (error) {
            console.error('Error fetching data:', error);
        }
  };
  
    React.useEffect(() => {
        fetchData();
        const interval = setInterval(fetchData, 5 * 60 * 1000);

        return () => clearInterval(interval);
    }, []);

    const user = Cookies.get('username');

    // const handleToggle = async (id, isChecked) => {
    //     const baseApiUrl = isChecked ? startApiUrl : stopApiUrl;
    //     const apiUrl = `${baseApiUrl}?id=${id}&user=${user}`;

    //     const response = await fetch(apiUrl, {
    //     method: 'POST',
    //     headers: {
    //         'Content-Type': 'application/json'
    //     },
    //     body: JSON.stringify({ id })
    //     });

    //     const data = await response.json();

    //     if (data.success) {
    //     console.log(data);
    //     const updatedRows = rows.map(row => {
    //         if (row.id === id) {
    //         return { ...row, is_running: isChecked ? '1' : '0', remark: data.remark };
    //         }
    //         return row;
    //     });
    //     setRows(updatedRows);
    //     } else {
    //     console.error(`Error ${data.errorcode}: ${data.errormessage}`);
    //     }
    // };

    const getRowClassName = (params) => {
        const activityDate = new Date(params.row.last_activity);
        const now = new Date();
        const fiveMinutes = 5 * 60 * 1000;
        const isOlderThanFiveMinutes = now - activityDate > fiveMinutes;
        const isRunning = params.row.is_running === '1';
    
        return isRunning && isOlderThanFiveMinutes ? 'row-highlight' : '';
    };

    const columns = [
        { field: 'id', headerName: 'ID', width: 60 },
        { field: 'name', headerName: 'Name', width: 120 },
        { field: 'ip_address', headerName: 'IP adress', width: 120 },
        { field: 'is_running', headerName: 'Running', type: 'number', width: 100 },
        { field: 'last_activity', headerName: 'Last Activity', width: 150 },
        { field: 'remark', headerName: 'Remark', width: 540 },
        // {
        //     field: 'actions',
        //     headerName: 'Start/Stop',
        //     width: 180,
        //     renderCell: (params) => {
        //         const isRunning = params.row.is_running === '1';
        //         const isVM = params.row.vm === '1';

        //         if (!isVM) {
        //         return <div></div>;
        //         }

        //         return (
        //             <SwitchControlled
        //                 isVisible={isVM}
        //                 isChecked={isRunning}
        //                 disabled={false}
        //                 onToggle={(newChecked) => handleToggle(params.id, newChecked)}
        //             />
        //         );
        //     }
        // }
    ];

    return (
        <div>
            <h2 style={{ marginBottom: '15px'}}>P2A SERVERS</h2>
            <div style={{ height: 631, width: '100%' }}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    getRowClassName={getRowClassName}
                    initialState={{
                        pagination: {
                        paginationModel: { page: 0, pageSize: 10 },
                        },
                    }}
                    pageSizeOptions={[10, 20, 50]}
                />
            </div>
        </div>
    );
}
