// import React from 'react';
// import Button from '@mui/material/Button';
// import { useNavigate } from 'react-router-dom';


// function LogoutButton({ onLogout }) {
//     const navigate = useNavigate();

//     const handleLogout = () => {
//         console.log("Logout button clicked");
//         onLogout();
//         navigate('/login');
//     };

//     return <Button variant="contained" onClick={handleLogout}>Logout</Button>;
// }

// export default LogoutButton;

import React from 'react';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';

function LogoutButton({ onLogout }) {
    const navigate = useNavigate();

    const handleLogout = () => {
        console.log("Logout button clicked");
        onLogout();
        navigate('/login');
    };

    return (
        <Button variant="contained" onClick={handleLogout} startIcon={<LogoutRoundedIcon />}>
            Logout
        </Button>
    );
}

export default LogoutButton;