import React, { useState, useEffect } from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

const SendDirectIsdDropdown = ({ onIsdsSelected, selectedValue }) => {
    const [sendDirectIsds, setSendDirectIsds] = useState([]);
    const [selectedSendTo, setSelectedSendTo] = useState('');

    useEffect(() => {
        const fetchIds = async () => {
        try {
            const response = await fetch('https://smsauth.messagemate.at/goip_api/listCountry.php');
            const data = await response.json();
            if (data && data.data) {
                setSendDirectIsds(data.data);
            }
        } catch (error) {
            console.error('Error while fetching data:', error);
        }
        };

        fetchIds();
    }, []);

    useEffect(() => {
        setSelectedSendTo(selectedValue);
    }, [selectedValue]);

    const handleChange = async (event) => {
        const selectedIsd = event.target.value;
        setSelectedSendTo(selectedIsd);

        try {
        const response = await fetch(`https://smsauth.messagemate.at/goip_api/listReceiverByISD.php?isd=${selectedIsd}`);
        const data = await response.json();
        if (data && data.data) {
            const phoneNumbers = data.data.map(item => item.number);
            onIsdsSelected(phoneNumbers);
        }
        } catch (error) {
        console.error('Error while fetching numbers:', error);
        }
    };

    return (
        <FormControl sx={{ width: 234 }}>
        <InputLabel id="sendto-select-label">by ISD</InputLabel>
        <Select
            labelId="sendto-select-label"
            id="sendto-select"
            value={selectedSendTo}
            label="sendto"
            onChange={handleChange}
        >
            {sendDirectIsds.map((DbIsd) => (
            <MenuItem key={DbIsd.isd} value={DbIsd.isd}>
                {DbIsd.isd} ({DbIsd.name})
            </MenuItem>
            ))}
        </Select>
        </FormControl>
    );
};

export default SendDirectIsdDropdown;