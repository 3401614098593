import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import '../numbersForm/NumbersForm.scss';
import GoipProviderDropdown from "../goip-provider-dropdown/GoipProviderDropdown";
import GoipIdDropdown from "../goip-id-dropdown/GoipIdDropdown";
import GoipSendtoDropdown from "../goip-sendto-dropdown/GoipSendtoDropdown";

import { v4 as uuidv4 } from 'uuid';


export default function GoIp() {

    const initialUuid = uuidv4(); // Save initial UUID
    const initialUuidRef = useRef(uuidv4()); // Creates and stores the UUID value when the first render is made
    const [runid, setRunid] = useState(initialUuidRef.current);
    // const [runid, setRunid] = useState(initialUuid);
    const [campaign, setCampaign] = useState("");
    const [userid, setUserid] = useState("2");
    const [type, setType] = useState("4");
    const [goipid, setGoipid] = useState("");
    const [providerId, setProviderId] = useState("");
    const [providerProv, setProviderProv] = useState("");
    const [sendto, setSendto] = useState("");
    const [maxsms, setMaxsms] = useState("1");
    const [sleep, setSleep] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [errors, setErrors] = useState({});

    const location = useLocation();

    useEffect(() => {
        if (location.state && location.state.campaignData) {
            const { campaignData } = location.state;
            
            setCampaign(campaignData.campaign.campaignname);
            setProviderId(campaignData.provider.id);
            setProviderProv(campaignData.provider.prov);
            setGoipid(campaignData.goip.goipid);
            
            const newRunId = `${campaignData.provider.prov}_${campaignData.goip.goipid}_${initialUuidRef.current}`;
            setRunid(newRunId);
        }
    }, [location]);

    const handleProviderChange = (id, prov) => {
        setProviderId(id);
        setProviderProv(prov);
        
        setRunid(`${prov}_${goipid ? goipid + '_' : ''}${initialUuid}`);
        setErrors({ ...errors, provider: validateDropdown(id, "Provider") });
    };
    
    const handleGoipIdChange = (goipIdValue) => {
        setGoipid(goipIdValue);

        setRunid(`${providerProv}_${goipIdValue}_${initialUuid}`);
        setErrors({ ...errors, goipId: validateDropdown(goipIdValue, "Goip ID") });
    };

    const handleModalClose = () => {
        
    };

    const handleSubmit = async () => {

        const campaignError = validateCampaign(campaign);
        const sleepError = validateSleep(sleep);
        const providerError = validateDropdown(providerId, "Provider");
        const goipIdError = validateDropdown(goipid, "Goip ID");
        const sendtoError = validateDropdown(sendto, "Send to");
  
        if (campaignError || sleepError || providerError || goipIdError || sendtoError) {
            setErrors({
                campaign: campaignError,
                sleep: sleepError,
                provider: providerError,
                goipId: goipIdError,
                sendto: sendtoError
            });
            return;
        }

        const formData = new FormData();
        formData.append('run_id', runid);
        formData.append('campaign', campaign);
        formData.append('userid', userid);
        formData.append('type', type);
        formData.append('providerid', providerId);
        formData.append('goipid', goipid);
        formData.append('maxsms', maxsms);
        formData.append('sendto', sendto);
        formData.append('sleep', sleep);


        try {
            const response = await fetch('https://smsauth.messagemate.at/goip_api/qdRun.php', {
                method: 'POST',
                body: formData,
            });
            
            const result = await response.json();
            console.log(result);

            if (result.success === true || result.success === 'true') {
                setRunid(uuidv4());
                setCampaign("");
                setProviderId("");
                setProviderProv("");
                setGoipid("");
                setSendto("");
                setMaxsms("");
                setSleep("");

                setIsModalOpen(true);
            }
        } catch (error) {
            console.error("There was an error sending the data", error);
        }
    };

    // Validation function -------------------

    const validateCampaign = (value) => {
        if (!value.trim()) return "Campaign is required.";
        return "";
    };

    const validateSleep = (value) => {
        if (!value) return "Sleep is required.";
        if (isNaN(value) || parseInt(value) < 1) return "Sleep must be a number greater than 0.";
        return "";
    };

    const validateDropdown = (value, field) => {
        if (!value) return `${field} is required.`;
        return "";
    };

    const validateMaxSms = (value) => {
        if (!value) return "Max SMS is required.";
        if (isNaN(value) || parseInt(value) < 1) return "Max SMS must be a number greater than 0.";
        return "";
    };
  

    const handleCampaignChange = (e) => {
        setCampaign(e.target.value);
        setErrors({ ...errors, campaign: validateCampaign(e.target.value) });
    };

    const handleSleepChange = (e) => {
        setSleep(e.target.value);
        setErrors({ ...errors, sleep: validateSleep(e.target.value) });
    };

    const handleSendtoChange = (value) => {
        setSendto(value);
        setErrors({ ...errors, sendto: validateDropdown(value, "Send to") });
    };

    const handleMaxSmsChange = (e) => {
        setMaxsms(e.target.value);
        setErrors({ ...errors, maxSms: validateMaxSms(e.target.value) });
    };

    function FormSubmittedModal({ open, onRefresh }) {
        const theme = useTheme();
        const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    
        const handleOk = () => {
            onRefresh();
        };
  
        return (
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleOk}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    {"Campaign Started"}
                </DialogTitle>
                <DialogActions>
                    <Button onClick={handleOk} autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    return (
        <Stack spacing={5} sx={{ width: 300 }}>
            <div className='inputs-wrapper'>
                <TextField fullWidth disabled id="runid" label="Run ID" variant="outlined" value={runid} required />
            </div>
            <div className='inputs-wrapper'>
                <TextField 
                    fullWidth 
                    error={!!errors.campaign}
                    helperText={errors.campaign}
                    id="campaign" 
                    label="Campaign" 
                    variant="outlined" 
                    value={campaign}
                    onChange={handleCampaignChange} 
                    required 
                />
            </div>
            <div className='inputs-wrapper'>
                <TextField disabled id="userid" label="User ID" variant="outlined" value="2" required onChange={(e) => setUserid(e.target.value)} />
                <TextField disabled id="type" label="Type" variant="outlined" value="4" required onChange={(e) => setType(e.target.value)} />
            </div>
            <div className='inputs-wrapper'>
                <GoipProviderDropdown 
                    selectedProviderProp={providerId}
                    onChange={handleProviderChange}
                    error={!!errors.provider}
                    helperText={errors.provider}
                />
                <GoipIdDropdown 
                    selectedGoipId={goipid} 
                    providerId={providerId} 
                    onChange={handleGoipIdChange}
                    error={!!errors.goipId}
                    helperText={errors.goipId}
                />
            </div>
            <div className='inputs-wrapper'>
                <GoipSendtoDropdown 
                    onChange={handleSendtoChange}
                    error={!!errors.sendto}
                    helperText={errors.sendto}
                />
                <TextField 
                    id="maxsms" 
                    label="Max SMS" 
                    variant="outlined" 
                    type="number" 
                    required
                    inputProps={{ min: "1", step: "1" }}
                    value={maxsms}
                    onChange={handleMaxSmsChange} 
                    error={!!errors.maxSms}
                    helperText={errors.maxSms}
                />
            </div>
            <div className='inputs-wrapper'>
                <TextField 
                    error={!!errors.sleep}
                    helperText={errors.sleep}
                    id="sleep" 
                    label="Sleep" 
                    variant="outlined" 
                    value={sleep}
                    onChange={handleSleepChange}
                    required 
                />
            </div>
            <div className='inputs-wrapper'>
                <Button variant="outlined" onClick={handleSubmit}>run</Button>
            </div>

            <FormSubmittedModal open={isModalOpen} onRefresh={handleModalClose} />
        </Stack>
    );
}