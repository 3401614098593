import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';

import LoginForm from "../loginForm/LoginForm";
import Dashboard from "../dashboard/Dashboard";
import DashboardSid from "../dashboardSid/DashboardSid";
import Index from "../index/Index";
import Admin from "../admin/Admin";
import NavigationMenu from "../navigationMenu/NavigationMenu";
import NavigationMenuP2A from "../navigationMenuP2A/NavigationMenuP2A";
import NavigationMenuP2P from "../navigationMenuP2P/NavigationMenuP2P";
import NavigationMenuIndex from "../navigationMenuIndex/NavigationMenuIndex";
import NavigationMenuAdmin from "../navigationMenuAdmin/NavigationMenuAdmin";
import NumbersList from "../numbersList/NumbersList";
import NumbersForm from "../numbersForm/NumbersForm";
import NumbersEdit from "../numbersEdit/NumbersEdit";
import FileValidateAndUpload from "../fileValidateAndUpload/FileValidateAndUpload";
import GoIp from "../goIp/GoIp";
import BatchList from "../batchList/BatchList";
import BatchAdd from "../batchAdd/BatchAdd";
import BatchEdit from "../batchEdit/BatchEdit";
import BatchRun from "../batchRun/BatchRun";
import BatchSex from "../batchSex/BatchSex";
import Worksheet from "../worksheet/Worksheet";
import UserList from "../userList/UserList";
import UserEdit from "../userEdit/UserEdit";
import UserAdd from "../userAdd/UserAdd";
import SendDirect from "../sendDirect/SendDirect"
import P2ADashboard from "../p2aDashboard/P2ADashboard"
import P2AReceiver from "../p2aReceiver/P2AReceiver"
import P2AReceiverAdd from "../p2aReceiverAdd/P2AReceiverAdd"
import P2AReceiverEdit from "../p2aReceiverEdit/P2AReceiverEdit"
import P2AReports from "../p2aReports/P2AReports"
import P2ASendCampaign from "../p2aSendCampaign/P2ASendCampaign"
import SmsInbox from "../smsInbox/SmsInbox"
import GoipChannelConfig from "../goipChannelConfig/GoipChannelConfig"
import P2AGroups from "../p2aGroups/P2AGroups"
import P2AGroupsEdit from "../p2aGroupsEdit/P2AGroupsEdit"
import P2AGroupsAdd from "../p2aGroupsAdd/P2AGroupsAdd"
import Servers from "../servers/Servers"
import ServersErrors from "../serversErrors/ServersErrors"
import P2ARunningCampaigns from "../p2aRunningCampaigns/P2ARunningCampaigns"
import P2PRevenue from "../p2pRevenue/P2PRevenue"
import P2PSid from "../p2pSid/P2PSid"





const Content = ({ isAuthenticated, onLogout, onLoginSuccess }) => {
    const location = useLocation();

    // Navigation render logic
    
    const renderNavigationMenu = () => {
        if (location.pathname.includes('/p2a')) {
            return <NavigationMenuP2A onLogout={onLogout} />;
        } else if (location.pathname.includes('/index')) {
            return <NavigationMenuIndex onLogout={onLogout} />;
        } else if (location.pathname.includes('/admin')) {
            return <NavigationMenuAdmin onLogout={onLogout} />;
        } else if (location.pathname.includes('/p2p')) {
            return <NavigationMenuP2P onLogout={onLogout} />;
        } else {
            return <NavigationMenu onLogout={onLogout} />;
        }
    };

    // Routes and redirection

    return (
        <div>
            { isAuthenticated && renderNavigationMenu() }
            <Routes>
                <Route path="/" element={ isAuthenticated ? <Navigate to="/index" /> : <Navigate to="/login" /> } />
                <Route path="/index" element={ isAuthenticated ? <Index /> : <Navigate to="/login" /> } />
                <Route 
                    path="/admin" 
                    element={ 
                        isAuthenticated && Cookies.get('role') === 'admin' 
                        ? <Admin /> 
                        : <Navigate to="/login" /> 
                    } 
                />
                {/* <Route path="/" element={ isAuthenticated ? <Navigate to="/dashboard" /> : <Navigate to="/login" /> } /> */}
                <Route path="/login" element={ isAuthenticated ? <Navigate to="/index" /> : <LoginForm isAuthenticated={isAuthenticated} onLoginSuccess={onLoginSuccess} /> } />
                <Route path="/dashboard" element={ isAuthenticated ? <Dashboard isAuthenticated={isAuthenticated}/> : <Navigate to="/login" /> } />
                <Route path="/dashboard-test" element={ isAuthenticated ? <Dashboard isAuthenticated={isAuthenticated} isTest={true}/> : <Navigate to="/login" /> } />
                <Route path="/dashboard-sid" element={ isAuthenticated ? <DashboardSid/> : <Navigate to="/login" /> } />
                <Route path="/servers" element={ isAuthenticated ? <Servers /> : <Navigate to="/login" /> } />
                <Route path="/numbers-list" element={ isAuthenticated ? <NumbersList /> : <Navigate to="/login" /> } />
                <Route path="/numbers-add" element={ isAuthenticated ? <NumbersForm /> : <Navigate to="/login" /> } />
                <Route path="/numbers-import" element={
                    isAuthenticated ? 
                    <FileValidateAndUpload 
                        templateDownloadUrl="https://smsadmin.schrego.at/files/import_numbers_template.xlsx"
                        uploadUrl="https://smsauth.messagemate.at/api/importNumbers.php"
                    /> : 
                    <Navigate to="/login" />
                } />
                <Route path="/numbers-edit/:id" element={ isAuthenticated ? <NumbersEdit /> : <Navigate to="/login" /> } />
                <Route path="/batch-list" element={ isAuthenticated ? <BatchList /> : <Navigate to="/login" /> } />
                <Route path="/batch-add" element={ isAuthenticated ? <BatchAdd /> : <Navigate to="/login" /> } />
                <Route path="/batch-run" element={ isAuthenticated ? <BatchRun /> : <Navigate to="/login" /> } />
                <Route path="/batch-sex" element={ isAuthenticated ? <BatchSex /> : <Navigate to="/login" /> } />
                <Route 
                    path="/batch-test" 
                    element={ 
                        isAuthenticated 
                        ? <BatchRun 
                            listApiUrl="https://smsauth.messagemate.at/api/listSid.php?active=9"
                            startApiUrl="https://smsauth.messagemate.at/api/startTestSid.php"
                            stopApiUrl="https://smsauth.messagemate.at/api/stopTestSid.php"
                            deleteApiUrl = 'https://smsauth.messagemate.at/api/removeFromTest.php'
                            addApiUrl = 'https://smsauth.messagemate.at/api/addToTest.php'
                            header="Test Run"
                            dropdownApiUrl="https://smsauth.messagemate.at/api/listForTest.php"
                            inputText = 'TEST'
                        /> 
                        : <Navigate to="/login" /> 
                    } 
                />
                <Route path="/batch-edit/:id" element={ isAuthenticated ? <BatchEdit /> : <Navigate to="/login" /> } />
                <Route path="/worksheet-progress" element={ isAuthenticated ? <Worksheet /> : <Navigate to="/login" /> } />
                <Route path="/worksheet-todo" element={ isAuthenticated ? <Worksheet /> : <Navigate to="/login" /> } />
                <Route path="/worksheet-special" element={ isAuthenticated ? <Worksheet /> : <Navigate to="/login" /> } />
                <Route path="/worksheet-applist" element={ isAuthenticated ? <Worksheet /> : <Navigate to="/login" /> } />
                <Route path="/worksheet-password_recovery" element={ isAuthenticated ? <Worksheet /> : <Navigate to="/login" /> } />
                <Route path="/admin/users-list" element={ isAuthenticated ? <UserList /> : <Navigate to="/login" /> } />
                <Route path="/admin/users-edit/:id" element={ isAuthenticated ? <UserEdit /> : <Navigate to="/login" /> } />
                <Route path="/admin/users-add" element={ isAuthenticated ? <UserAdd /> : <Navigate to="/login" /> } />
                <Route path="/p2a" element={ isAuthenticated ? <P2ADashboard /> : <Navigate to="/login" /> } />
                <Route path="/p2a/send-goip" element={ isAuthenticated ? <GoIp /> : <Navigate to="/login" /> } />
                <Route path="/p2a/send-direct" element={ isAuthenticated ? <SendDirect /> : <Navigate to="/login" /> } />
                <Route path="/p2a/send-campaign" element={ isAuthenticated ? <P2ASendCampaign /> : <Navigate to="/login" /> } />
                <Route path="/p2a/receiver" element={ isAuthenticated ? <P2AReceiver /> : <Navigate to="/login" /> } />
                <Route path="/p2a/receiver-add" element={ isAuthenticated ? <P2AReceiverAdd /> : <Navigate to="/login" /> } />
                <Route path="/p2a/receiver-edit/:id" element={ isAuthenticated ? <P2AReceiverEdit /> : <Navigate to="/login" /> } />
                <Route path="/p2a/receiver-import" element={
                    isAuthenticated ? 
                    <FileValidateAndUpload 
                        templateDownloadUrl="https://smsadmin.schrego.at/files/receiver_import_template.xlsx"
                        uploadUrl="https://smsadmin.schrego.at/functions/importReceiver.php"
                    /> : 
                    <Navigate to="/login" />
                } />
                <Route path="/p2a/reports" element={ isAuthenticated ? <P2AReports /> : <Navigate to="/login" /> } />
                <Route path="/p2a/sms-inbox" element={ isAuthenticated ? <SmsInbox /> : <Navigate to="/login" /> } />
                <Route path="/p2a/goip-channel-config" element={ isAuthenticated ? <GoipChannelConfig /> : <Navigate to="/login" /> } />
                <Route path="/p2a/groups-list" element={ isAuthenticated ? <P2AGroups /> : <Navigate to="/login" /> } />
                <Route path="/p2a/groups-edit/:id" element={ isAuthenticated ? <P2AGroupsEdit /> : <Navigate to="/login" /> } />
                <Route path="/p2a/groups-add" element={ isAuthenticated ? <P2AGroupsAdd /> : <Navigate to="/login" /> } />
                <Route path="/p2a/running-campaigns" element={ isAuthenticated ? <P2ARunningCampaigns /> : <Navigate to="/login" /> } />
                <Route path="/p2p/dashboard" element={ isAuthenticated ? <ServersErrors /> : <Navigate to="/login" /> } />
                <Route path="/p2p/daily-revenue" element={ isAuthenticated ? <P2PRevenue /> : <Navigate to="/login" /> } />
                <Route path="/p2p/monthly-revenue" element={ isAuthenticated ? <P2PRevenue /> : <Navigate to="/login" /> } />
                <Route path="/p2p/sid" element={ isAuthenticated ? <P2PSid /> : <Navigate to="/login" /> } />
            </Routes>
        </div>
    );
};


const App = () => {


    const [isAuthenticated, setIsAuthenticated] = useState(!!Cookies.get('isAuthenticated')); 



    const handleLogoutSuccess = () => {
        setIsAuthenticated(false);
        Cookies.remove('isAuthenticated');
        Cookies.remove('username');
        Cookies.remove('role');
    };
    const handleLoginSuccess = () => {
        setIsAuthenticated(true);
        Cookies.set('isAuthenticated', true, { expires: 7 });
    };


    return (
        <Router>
            <div className="app">
                <Content 
                  isAuthenticated={isAuthenticated} 
                  onLogout={handleLogoutSuccess} 
                  onLoginSuccess={handleLoginSuccess}
                />
            </div>
        </Router>
    );
};

export default App;

