import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Link } from 'react-router-dom';
import LogoutButton from '../logoutButton/LogoutButton';
import Cookies from 'js-cookie';
// import './NavigationMenu.scss'

export default function NavigationMenu({ onLogout }) {

    const user = Cookies.get('username');
    const role = Cookies.get('role');
    const [anchorElAdmin, setAnchorElAdmin] = React.useState(null);

    const handleClickAdmin = (event) => {
        setAnchorElAdmin(event.currentTarget);
    };

    const handleCloseAdmin = () => {
        setAnchorElAdmin(null);
    };

    return (
        <nav className='main-nav'>
            <Button component={Link} to="/index">
                Home
            </Button>
            {role === 'admin' && (
                <>
                <Button
                    id="admin-button"
                    aria-controls={Boolean(anchorElAdmin) ? 'admin-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={Boolean(anchorElAdmin) ? 'true' : undefined}
                    onClick={handleClickAdmin}
                >
                    Users
                </Button>
                <Menu
                    id="admin-menu"
                    anchorEl={anchorElAdmin}
                    open={Boolean(anchorElAdmin)}
                    onClose={handleCloseAdmin}
                    MenuListProps={{
                        'aria-labelledby': 'admin-button',
                    }}
                >
                    <MenuItem component={Link} to="/admin/users-list" onClick={handleCloseAdmin}>
                    Users list
                    </MenuItem>
                    <MenuItem component={Link} to="/admin/users-add" onClick={handleCloseAdmin}>
                    Add Users
                    </MenuItem>
                    </Menu>
                </>
            )}
            <div className="logout-button-wrapper">
                <span className="username"><strong>{user}</strong></span>
                <LogoutButton onLogout={onLogout} />
            </div>
        </nav>
    );
}
