import React, { useState, useEffect } from 'react';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
// import { useLocation } from 'react-router-dom';
import { useParams, useNavigate } from 'react-router-dom';
import P2AGroupsCrowdDropdown from "../p2aGroupsCrowdDropdown/P2AGroupsCrowdDropdown";




export default function P2AGroupEdit() {
    const [name, setName] = useState("");
    const [crowdId, setCrowdId] = useState("");
    const [info, setInfo] = useState("");
    const { id } = useParams();
    const navigate = useNavigate();

    const handleCrowdChange = (id) => {
        setCrowdId(id);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`https://smsauth.messagemate.at/goip_api/getGroup.php?id=${id}`);
                const result = await response.json();

                if (result.errorcode === '200'){
                    const data = result.data;

                    setName(data.name);
                    setInfo(data.info);
                    setCrowdId(data.crowid);
                }



            } catch (error) {
                console.error("There was an error fetching the data", error);
            }
        };

        fetchData();
        // eslint-disable-next-line
    }, [id]);
  

    const handleSubmit = async () => {
        const formData = new FormData();
        formData.append('id', id)
        formData.append('name', name);
        formData.append('crowdid', crowdId);
        formData.append('info', info);

        console.log(formData);
    
        try {
            const response = await fetch(`https://smsauth.messagemate.at/goip_api/editGroup.php?id=${id}`, {
                method: 'POST',
                body: formData,
            })
            .then(response => response.json())
            .then(data => {
              console.log(data);
              if (data.success) {
                navigate('/p2a/groups-list');
              } else {
                // error
              }
            })
            
            const result = await response.json();
            console.log(result);
        } catch (error) {
            console.error("There was an error sending the data", error);
        }
    };
  
    return (
      <Stack>
        <h2 className='header'>Group EDIT</h2>
        <div className='inputs-wrapper'>
          <TextField id="name" label="Name" variant="outlined" value={name} required onChange={(e) => setName(e.target.value)} />
        </div>
        <div className='inputs-wrapper'>
          <TextField id="info" label="Info" variant="outlined" value={info} required onChange={(e) => setInfo(e.target.value)} />
        </div>
        <div className='inputs-wrapper'>
          <P2AGroupsCrowdDropdown className="custom-input" value={crowdId} onChange={handleCrowdChange}/>
        </div>
        <div className='inputs-wrapper'>
          <Button variant="outlined" onClick={handleSubmit}>save</Button>
        </div>
      </Stack>
    );
}


