import React, { useState } from 'react';
import { Button, Box, Input } from '@mui/material';
import * as XLSX from 'xlsx';

export default function FileValidateAndUpload({ templateDownloadUrl, uploadUrl }) {
    const [validatedData, setValidatedData] = useState(null);
    const [errorText, setErrorText] = useState('');

    const handleDownloadTemplate = () => {
        window.open(templateDownloadUrl, '_blank');
    };


    const validateFile = (rows) => {
        let isValid = true;

        for (let row of rows) {
            if (!row['start_range'] || !row['end_range'] || row['end_range'] < row['start_range']) {
                isValid = false;
                setErrorText('File data is not valid. Check "start_range" and "end_range" fields');
                break;
            }

            for (let key in row) {
                if (!row[key]) {
                    isValid = false;
                    setErrorText('File data is not valid. Check "start_range" and "end_range" fields');
                    break;
                }
            }

            if (!isValid) {
                console.log('file data not valid');
                break;
            }
        }

        return isValid ? rows : null;
    };

    const uploadData = () => {
        if (validatedData) {
            console.log('file is valid, starting fetch data...');
            fetch(uploadUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(validatedData),
            })
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`Server responded with status: ${response.status}`);
                }
                return response.json();
            })
            .then((data) => console.log(data))
            .catch((error) => console.error('Fetch error:', error));
        } else {
            console.error('No valid data to upload');
        }
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = function(e) {
                const data = new Uint8Array(e.target.result);
                const workbook = XLSX.read(data, {type: 'array'});
                const firstSheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[firstSheetName];
                const rows = XLSX.utils.sheet_to_json(worksheet);
                const validData = validateFile(rows);
                if (validData) {
                    setValidatedData(validData);
                } else {
                    setValidatedData(null);
                }
            };
            reader.readAsArrayBuffer(file);
        }
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Button className="download-btn" variant="contained" color="primary" onClick={handleDownloadTemplate}>
                Download Template
            </Button>
            <Input 
                type="file"
                onChange={handleFileChange}
                inputProps={{ accept: '.csv' }} // Accept CSV-files only
            />
            {errorText && <div>{errorText}</div>}
            <Button 
                variant="contained" 
                color="primary" 
                component="span"
                onClick={uploadData}
            >
                Upload
            </Button>
        </Box>
    );
}
