import React, { useState } from 'react';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
// import MenuItem from '@mui/material/MenuItem';
// import Box from '@mui/material/Box';
// import Select from '@mui/material/Select';
// import InputLabel from '@mui/material/InputLabel';
// import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useNavigate } from 'react-router-dom';
import './BatchAdd.scss';




export default function BatchAdd() {
    const [sid, setSid] = useState("");
    const [name, setName] = useState("");
    const [remark, setRemark] = useState("");
    const [url, setUrl] = useState("");
    const [script, setScript] = useState("");
    const [isActive, setIsActive] = useState(true);

    const navigate = useNavigate();

    const [errors, setErrors] = useState({});

    // Textfield validate
    const validateRequiredText = (value) => {
        return value.trim() ? "" : "This field is required.";
    };

    const handleSubmit = async () => {

        const validationErrors = {
            sid: validateRequiredText(sid),
            name: validateRequiredText(name),
            url: validateRequiredText(url),
            script: validateRequiredText(script)
        };

        const hasErrors = Object.values(validationErrors).some(error => error);
    
        if (hasErrors) {
            setErrors(validationErrors);
            return;
        }

        setErrors({});

        const formData = new FormData();
        formData.append('sid', sid);
        formData.append('name', name);
        formData.append('remark', remark);
        formData.append('url', url);
        formData.append('script', script);
        formData.append('is_active', isActive);
    
        try {
            const response = await fetch('https://smsauth.messagemate.at/api/addBatchlist.php', {
                method: 'POST',
                body: formData,
            })
            .then(response => response.json())
            .then(data => {
              if (data.success) {
                navigate('/batch-list');
              } else {
                // Обработка ошибки
              }
            })
            
            const result = await response.json();
            console.log(result);
        } catch (error) {
            console.error("There was an error sending the data", error);
        }
    };

  
    return (
        <Stack>
            <h2 className='header'>Batch List ADD</h2>
            <div className='inputs-wrapper'>
                <TextField 
                    error={!!errors.sid}
                    helperText={errors.sid}
                    id="sid" 
                    label="SID" 
                    variant="outlined" 
                    required 
                    onChange={(e) => setSid(e.target.value)} 
                />
            </div>
            <div className='inputs-wrapper'>
                <TextField 
                    error={!!errors.name}
                    helperText={errors.name}
                    id="name" 
                    label="NAME" 
                    variant="outlined" 
                    required 
                    onChange={(e) => setName(e.target.value)} 
                />
            </div>
            <div className='inputs-wrapper'>
                <TextField multiline id="remark" label="REMARK" variant="outlined" onChange={(e) => setRemark(e.target.value)} />
            </div>
            <div className='inputs-wrapper'>
                <TextField 
                    error={!!errors.url}
                    helperText={errors.url}
                    id="url" 
                    label="URL" 
                    variant="outlined" 
                    required 
                    onChange={(e) => setUrl(e.target.value)} 
                />
            </div>
            <div className='inputs-wrapper'>
                <TextField 
                    error={!!errors.script}
                    helperText={errors.script}
                    id="script" 
                    label="SCRIPT" 
                    variant="outlined" 
                    required 
                    onChange={(e) => setScript(e.target.value)} 
                />
            </div>
            <div className='inputs-wrapper'>
                <FormControlLabel control={<Checkbox checked={isActive} value={isActive} onChange={() => setIsActive(!isActive)} />} label="active" />
            </div>
            <div className='inputs-wrapper'>
                <Button variant="outlined" onClick={handleSubmit}>save</Button>
            </div>
        </Stack>
    );
}


