import React, { useState, useEffect } from 'react';
import { FormControl, InputLabel, Select, MenuItem, Button } from '@mui/material';
import './P2AReports.scss';

const P2AReports = ({ onChange }) => {
    const [groups, setGroups] = useState([]);
    const [selectedId, setSelectedId] = useState('');
    const [reportData, setReportData] = useState(null);

    useEffect(() => {
        const fetchGroups = async () => {
        try {
            const response = await fetch('https://smsauth.messagemate.at/goip_api/listCampaigns.php');
            const data = await response.json();
            if (data && data.data) {
            setGroups(data.data);
            }
        } catch (error) {
            console.error('Error while fetching data:', error);
        }
        };

        fetchGroups();
    }, []);


    const handleChange = (event) => {
        setSelectedId(event.target.value);
    };

    const handleGoClick = async () => {
        try {
        const response = await fetch(`https://smsauth.messagemate.at/goip_api/getReport.php?id=${selectedId}`);
        const data = await response.json();
        setReportData(data.data);
        } catch (error) {
        console.error('Error while fetching report data:', error);
        }
    };

    const handleRefresh = () => {
        handleGoClick();
    }

    return (
        <div>
            <FormControl sx={{ width: 234, marginRight: 1 }}>
                <InputLabel id="group-select-label">Select Campaign</InputLabel>
                <Select
                labelId="group-select-label"
                id="group-select"
                label="Select Campaign"
                value={selectedId}
                onChange={handleChange}
                >
                {groups.map((group) => (
                    <MenuItem key={group.id} value={group.id}>
                    {group.campaignname}
                    </MenuItem>
                ))}
                </Select>
            </FormControl>
            <div className="reports__btn-wrapper">
                <Button className="reports__btn" variant="outlined" onClick={handleGoClick}>Go</Button>
                <Button variant="outlined" className="reports__btn-refresh" onClick={handleRefresh}>refresh</Button>
            </div>
            {reportData && (
                <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: '20px' }}>
                    <div>
                        <h2>OUT</h2>
                        {reportData.map((item, index) => (
                        <div key={index} className={`reports__block reports__block--out ${item.out_goip && item.out_bnumber && item.out_date ? 'reports__block--green' : 'reports__block--red'}`}>
                            <p>Goip: {item.out_goip || ''}</p>
                            <p>B-Number: {item.out_bnumber || ''}</p>
                            <p>Date: {item.out_date ? item.out_date.split(' ')[0] : ''}</p>
                            <p>Time: {item.out_date ? item.out_date.split(' ')[1] : ''}</p>
                        </div>
                        ))}
                    </div>
                    <div>
                        <h2>IN</h2>
                        {reportData.map((item, index) => (
                        <div key={index} className={`reports__block reports__block--in ${item.in_anumber && item.in_bnumber && item.in_date ? 'reports__block--green' : 'reports__block--red'}`}>
                            <p>A-Number: {item.in_anumber || ''}</p>
                            <p>B-Number: {item.in_bnumber || ''}</p>
                            <p>Date: {item.in_date ? item.in_date.split(' ')[0] : ''}</p>
                            <p>Time: {item.in_date ? item.in_date.split(' ')[1] : ''}</p>
                        </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default P2AReports;