import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
// import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import { v4 as uuidv4 } from 'uuid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import '../sendDirect/SendDirect.scss';

import SendDirectIdDbDropdown from "../send-direct-idDB-dropdown/SendDirectIdDbDropdown";
import SendDirectIsdDropdown from "../send-direct-isd-dropdown/SendDirectIsdDropdown";
import SendDirectScvInput from "../send-direct-csv-input/SendDirectScvInput";
import SendDirectMessageDropdown from "../send-direct-message-dropdown/SendDirectMessageDropdown";
import GoipProviderDropdown from "../goip-provider-dropdown/GoipProviderDropdown";
import GoipIdDropdown from "../goip-id-dropdown/GoipIdDropdown";


export default function SendDirect() {

    const initialUuid = uuidv4(); // Save initial UUID
    // const [runid, setRunid] = useState(initialUuid);
    const initialUuidRef = useRef(uuidv4()); // Creates and stores the UUID value when the first render is made
    const [runid, setRunid] = useState(initialUuidRef.current);
    const [campaign, setCampaign] = useState("");
    const [goipid, setGoipid] = useState("");
    const [providerId, setProviderId] = useState("");
    const [providerProv, setProviderProv] = useState("");
    const [sendto, setSendto] = useState("");
    const [selectedId, setSelectedId] = useState("");
    const [selectedIsd, setSelectedIsd] = useState("")
    const [csvData, setCsvData] = useState([]);
    const [startDate, setStartDate] = useState(new Date());
    // const [endDate, setEndDate] = useState(null);
    const [hours, setHours] = useState("");
    const [runs, setRuns] = useState("");
    const [message, setMessage] = useState("");
    const [remainingChars, setRemainingChars] = useState(160);
    const [numbersCount, setnumbersCount] = useState(0);
    const [selectedMessageId, setSelectedMessageId] = useState("");
    const [formKey, setFormKey] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [errors, setErrors] = useState({});

    const location = useLocation();

    const prevCampaignDataRef = useRef();

    useEffect(() => {
    const { campaignData } = location.state || {};

    if (campaignData && prevCampaignDataRef.current !== campaignData) {
        console.log(location.state);
        console.log(campaignData);

        setCampaign(campaignData.campaign.campaignname);
        setProviderId(campaignData.provider.id);
        setProviderProv(campaignData.provider.prov);
        setGoipid(campaignData.goip.goipid);

        const newRunId = `${campaignData.provider.prov}_${campaignData.goip.goipid}_${initialUuidRef.current}`;
        setRunid(newRunId);

        if (Array.isArray(campaignData.numbers) && campaignData.numbers.length > 0) {
        const numbersString = campaignData.numbers.join(', ');
        setSendto(numbersString);
        updateNumbersCount(numbersString);
        }

        prevCampaignDataRef.current = campaignData;
    }
    }, [location]);

    const handleSendToChange = (event) => {
        const value = event.target.value;
        const regex = /^[0-9, ]*$/;
    
        if (regex.test(value)) {
            setSendto(value);
            const numbersArray = value.replace(/\s/g, '').split(',').filter(num => num !== '');
            setnumbersCount(numbersArray.length);
        }
    };

    const handleValidCsv = (data) => {
        setCsvData(data);
    };

    const handleSelectCsv = () => {
        const currentNumbers = sendto ? sendto.split(',').map(num => num.trim()) : [];
        const allNumbers = Array.from(new Set([...currentNumbers, ...csvData])).join(',');
        setSendto(allNumbers);
    };

    const [selectedNumbers, setSelectedNumbers] = useState([]);

    const handleIdsSelected = (numbers) => {
        setSelectedNumbers(numbers);
        setSelectedIsd("");
    };

    const handleIsdsSelected = (numbers) => {
        setSelectedNumbers(numbers);
        setSelectedId("");
    };

    const handleAddFromDb = () => {
        const currentNumbersArray = sendto.split(', ').filter(num => num.trim() !== '');
        const updatedNumbers = Array.from(new Set([...currentNumbersArray, ...selectedNumbers])).join(',');
    
        setSendto(updatedNumbers);
        updateNumbersCount(updatedNumbers);
    };

    const updateNumbersCount = (numbersString) => {
        const numbersArray = numbersString.replace(/\s/g, '').split(',').filter(num => num !== '');
        setnumbersCount(numbersArray.length);
    };

    const handleProviderChange = (id, prov) => {
        setProviderId(id);
        setProviderProv(prov);
        setRunid(`${prov}_${goipid ? goipid + '_' : ''}${initialUuid}`);
        setErrors({ ...errors, provider: validateDropdown(id, "Provider") });
      };
      
      const handleGoipIdChange = (goipIdValue) => {
        setGoipid(goipIdValue);
        setRunid(`${providerProv}_${goipIdValue}_${initialUuid}`);
        setErrors({ ...errors, goipId: validateDropdown(goipIdValue, "Goip ID") });
      };

    const handleMessageSelect = (messageData) => {
        const text = messageData.text || "";
        console.log(text)
        if (text.length <= 160) {
            setMessage(text);
            setSelectedMessageId(messageData.id);
            setRemainingChars(160 - text.length);
            setErrors({ ...errors, message: validateDropdown(text, "SMS Template") });
            console.log(text)
        }
    };
    
    const handleMessageChange = (event) => {
        const input = event.target.value;
        if (input.length <= 160) {
            setMessage(input);
            setRemainingChars(160 - input.length);
        }
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
    };


    const handleSubmit = async () => {
        const campaignError = validateCampaign(campaign);
        const goipIdError = validateDropdown(goipid, "Goip ID");
        const providerError = validateDropdown(providerId, "Provider");
        const runsError = validateDigitInput(runs);
        const hoursError = validateDigitInput(hours);
        const messageError = validateDropdown(message);
        const numbersError = numbersCount > 0 ? "" : "At least one number is required.";

        if (campaignError || goipIdError || providerError || runsError || hoursError || messageError || numbersError) {
            setErrors({
                campaign: campaignError,
                goipId: goipIdError,
                provider: providerError,
                runs: runsError,
                hours: hoursError,
                message: messageError,
                numbers: numbersError
            });
            return;
        }


        // Convert and format start date
        const timezoneOffset = startDate.getTimezoneOffset() * 60000;
        const adjustedDate = new Date(startDate.getTime() - timezoneOffset);
        const formattedStartDate = adjustedDate.toISOString().replace('T', ' ').substring(0, 19);

        const formData = new FormData();

        formData.append('numbers', sendto.trim());
        formData.append('run_id', runid);
        formData.append('campaign', campaign);
        formData.append('providerid', providerId);
        formData.append('goipid', goipid);
        formData.append('startdate', formattedStartDate);
        formData.append('timespan', hours);
        formData.append('message', selectedMessageId);
        formData.append('runs', runs);

        try {
            const response = await fetch('https://smsauth.messagemate.at/goip_api/directRun.php', {
                method: 'POST',
                body: formData,
            });
            
            const result = await response.json();
            console.log(result);

            setRunid(uuidv4());
            setCampaign("");
            setSendto("");
            setSelectedId("");
            setSelectedIsd("");
            setCsvData([]);
            setStartDate(new Date());
            setHours("");
            setMessage("");
            setRemainingChars(160);
            setSelectedMessageId("");

            setProviderId("");
            setProviderProv("");
            setGoipid("");

            setIsModalOpen(true);

            setFormKey(prevKey => prevKey + 1);

        } catch (error) {
            console.error("There was an error sending the data", error);
        }
    };

    // Validation function -------------------

    const validateCampaign = (value) => {
        if (!value.trim()) return "Campaign is required.";
        return "";
    };

    const handleCampaignChange = (e) => {
        setCampaign(e.target.value);
        setErrors({ ...errors, campaign: validateCampaign(e.target.value) });
    };

    const validateDropdown = (value, field) => {
        if (!value) return `${field} is required.`;
        return "";
    };

    const validateDigitInput = (value) => {
        if (!value) return "Field is required.";
        if (isNaN(value) || parseInt(value) < 1) return "Value must be a number greater than 0.";
        return "";
      };


    function FormSubmittedModal({ open, onRefresh }) {
        const theme = useTheme();
        const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    
        const handleOk = () => {
            onRefresh();
        };
    
        return (
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleOk}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    {"Campaign Started"}
                </DialogTitle>
                <DialogActions>
                    <Button onClick={handleOk} autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }


    return (
        <Stack spacing={5} sx={{ width: 300 }} key={formKey}>
            <div className='send-inputs-wrapper'>
                <div className='inputs-halfwidth-column'>
                    <TextField fullWidth disabled id="runid" label="Run ID" variant="outlined" value={runid} required />
                </div>
                <div className='inputs-halfwidth-column'>
                    <TextField 
                        fullWidth 
                        error={!!errors.campaign}
                        helperText={errors.campaign}
                        id="campaign" 
                        label="Campaign" 
                        variant="outlined" 
                        value={campaign}
                        onChange={handleCampaignChange} 
                        required 
                    />
                </div>
            </div>
            <div className='send-inputs-wrapper'>
                <div className='send-inputs-column send-inputs-column__left'>
                    <TextField
                    id="numbers-multiline"
                    label="Number(s)"
                    multiline
                    rows={8}
                    value={sendto}
                    onChange={handleSendToChange}
                    error={!!errors.numbers}
                    helperText={errors.numbers || `${numbersCount} numbers selected`}
                    />
                </div>
                <div className='send-inputs-column'>
                    <div className='send-inputs-row send-inputs-row__underline'>
                        <Button variant="outlined" className="direct-button" onClick={handleAddFromDb}>Select from DB</Button>
                        <SendDirectIdDbDropdown
                            selectedValue={selectedId}
                            onIdsSelected={handleIdsSelected}
                            onValueChange={setSelectedId}
                        />
                        <SendDirectIsdDropdown
                            selectedValue={selectedIsd}
                            onIsdsSelected={handleIsdsSelected}
                            onValueChange={setSelectedIsd}
                        />
                    </div>
                    <div className='send-inputs-row'>
                        <Button variant="outlined" className="direct-button" onClick={handleSelectCsv}>Select from CSV</Button>
                        <SendDirectScvInput className="file-input" onValidCsv={handleValidCsv} />
                    </div>
                </div>
            </div>

            <div className='send-inputs-wrapper'>
                <div className='send-inputs-column send-inputs-column__left'>
                    <GoipProviderDropdown 
                        className="custom-input" 
                        selectedProviderProp={providerId} 
                        onChange={handleProviderChange}
                        error={!!errors.provider}
                        helperText={errors.provider}
                    />
                </div>
                <div className='send-inputs-column'>
                    <GoipIdDropdown 
                        className="custom-input" 
                        selectedGoipId={goipid} 
                        providerId={providerId} 
                        onChange={handleGoipIdChange}
                        error={!!errors.goipId}
                        helperText={errors.goipId}
                    />
                </div>
            </div>

            <div className='send-inputs-wrapper'>
                 <LocalizationProvider dateAdapter={AdapterDateFns}> 
                    <div className='send-inputs-column send-inputs-column__left'>
                        <DateTimePicker
                            label="Start"
                            value={startDate}
                            format="yyyy-MM-dd HH:mm"
                            onChange={setStartDate}
                            renderInput={(props) => <TextField {...props} />}
                        />
                    </div>
                    <div className='send-inputs-column'>
                        <TextField 
                            id="hours" 
                            label="Timespan (Minutes)" 
                            variant="outlined" 
                            type="number" 
                            required 
                            inputProps={{ min: "1", step: "1" }}
                            onChange={(e) => {
                                setHours(e.target.value)
                                setErrors({ ...errors, hours: validateDigitInput(e.target.value) });
                                }
                            }
                            error={!!errors.hours}
                            helperText={errors.hours}
                        />
                    </div>
                </LocalizationProvider>
            </div>
            <div className='send-inputs-wrapper'>
                <div className='send-inputs-column send-inputs-column__left'>
                    <TextField
                        sx={{ width: 274 }}
                        id="message-multiline"
                        label="Message"
                        multiline
                        disabled
                        rows={8}
                        value={message}
                        onChange={handleMessageChange}
                        helperText={`${remainingChars} characters left`}
                    />
                </div>
                <div className='send-inputs-column'>
                    <div className='send-inputs-row'>
                        <SendDirectMessageDropdown 
                            onChange={handleMessageSelect}
                            error={!!errors.message}
                            helperText={"SMS Template is required"}
                        />
                    </div>
                    <div className='send-inputs-row'>
                        <TextField 
                            id="runs" 
                            label="Runs" 
                            variant="outlined" 
                            type="number" 
                            required 
                            inputProps={{ min: "1", step: "1" }}
                            onChange={(e) => {
                                setRuns(e.target.value)
                                setErrors({ ...errors, runs: validateDigitInput(e.target.value) });
                            }}
                            error={!!errors.runs}
                            helperText={errors.runs}
                        />
                    </div>
                </div>

            </div>
            <div className='send-inputs-wrapper'>
                <Button variant="outlined" onClick={handleSubmit}>run</Button>
            </div>
            <FormSubmittedModal open={isModalOpen} onRefresh={handleModalClose} />
        </Stack>
    );
}