import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Link } from 'react-router-dom';
import LogoutButton from '../logoutButton/LogoutButton';
import Cookies from 'js-cookie';
import './NavigationMenu.scss'

export default function NavigationMenu({ onLogout }) {

    const user = Cookies.get('username');
    // const role = Cookies.get('role');
    // const [anchorElAdmin, setAnchorElAdmin] = React.useState(null);

    const [anchorEl1, setAnchorEl1] = React.useState(null);
    const [anchorEl2, setAnchorEl2] = React.useState(null);
    const [anchorEl3, setAnchorEl3] = React.useState(null);
    // const [anchorEl4, setAnchorEl4] = React.useState(null);

    const open1 = Boolean(anchorEl1);
    const open2 = Boolean(anchorEl2);
    const open3 = Boolean(anchorEl3);
    // const open4 = Boolean(anchorEl4);

    // const handleClickAdmin = (event) => {
    //   setAnchorElAdmin(event.currentTarget);
    // };

    // const handleCloseAdmin = () => {
    //     setAnchorElAdmin(null);
    // };


    const handleClick1 = (event) => {
        setAnchorEl1(event.currentTarget);
    };

    const handleClick2 = (event) => {
        setAnchorEl2(event.currentTarget);
    };

    const handleClick3 = (event) => {
        setAnchorEl3(event.currentTarget);
    };

    // const handleClick4 = (event) => {
    //   setAnchorEl4(event.currentTarget);
    // }

    const handleClose = () => {
        setAnchorEl1(null);
        setAnchorEl2(null);
        setAnchorEl3(null);
        // setAnchorEl4(null);
    };

    return (
        <nav className='main-nav'>
            <Button component={Link} to="/index">
                Home
            </Button>
            <Button
                id="basic-button-1"
                aria-controls={open1 ? 'basic-menu-1' : undefined}
                aria-haspopup="true"
                aria-expanded={open1 ? 'true' : undefined}
                onClick={handleClick1}
            >
                Dashboard
            </Button>
            <Menu
                id="basic-menu-1"
                anchorEl={anchorEl1}
                open={open1}
                onClose={handleClose}
                MenuListProps={{
                'aria-labelledby': 'basic-button-1',
                }}
            >
                <MenuItem component={Link} to="/dashboard" onClick={handleClose}>Master</MenuItem>
                <MenuItem component={Link} to="/dashboard-test" onClick={handleClose}>Test</MenuItem>
                <MenuItem component={Link} to="/dashboard-sid" onClick={handleClose}>SID</MenuItem>
            </Menu>
            <Button component={Link} to="/servers">
                Servers
            </Button>
            <Button
                id="basic-button-2"
                aria-controls={open2 ? 'basic-menu-2' : undefined}
                aria-haspopup="true"
                aria-expanded={open2 ? 'true' : undefined}
                onClick={handleClick2}
            >
                SMS Numbers
            </Button>
            <Menu
                id="basic-menu-2"
                anchorEl={anchorEl2}
                open={open2}
                onClose={handleClose}
                MenuListProps={{
                'aria-labelledby': 'basic-button-2',
                }}
            >
                <MenuItem component={Link} to="/numbers-list" onClick={handleClose}>List</MenuItem>
                <MenuItem component={Link} to="/numbers-add" onClick={handleClose}>Add</MenuItem>
                <MenuItem component={Link} to="/numbers-import" onClick={handleClose}>Import</MenuItem>
            </Menu>
            <Button
                id="basic-button-3"
                aria-controls={open3 ? 'basic-menu-3' : undefined}
                aria-haspopup="true"
                aria-expanded={open3 ? 'true' : undefined}
                onClick={handleClick3}
            >
                Batch List
            </Button>
            <Menu
                id="basic-menu-3"
                anchorEl={anchorEl3}
                open={open3}
                onClose={handleClose}
                MenuListProps={{
                'aria-labelledby': 'basic-button-3',
                }}
            >
                <MenuItem component={Link} to="/batch-list" onClick={handleClose}>List</MenuItem>
                <MenuItem component={Link} to="/batch-add" onClick={handleClose}>Add</MenuItem>
                <MenuItem component={Link} to="/batch-run" onClick={handleClose}>Master</MenuItem>
                <MenuItem component={Link} to="/batch-test" onClick={handleClose}>Test</MenuItem>
                <MenuItem component={Link} to="/batch-sex" onClick={handleClose}>Amateursex</MenuItem>
            </Menu>
            <div className="logout-button-wrapper">
                <span className="username"><strong>{user}</strong></span>
                <LogoutButton onLogout={onLogout} />
            </div>
        </nav>
    );
}
