import React, { useState } from 'react';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
// import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import '../batchAdd/BatchAdd.scss';




export default function UserEdit() {

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isAdmin, setIsAdmin] = useState(true);
    const username = Cookies.get('username');
    const navigate = useNavigate();


    const handleSubmit = async () => {
        const formData = new FormData();
        formData.append('name', name);
        formData.append('email', email);
        formData.append('password', password);
        formData.append('role', isAdmin ? 'admin' : 'user');
        formData.append('username', username);

        console.log(formData);
    
        try {
            const response = await fetch('https://smsauth.messagemate.at/api/addUser.php', {
                method: 'POST',
                body: formData,
            })
            .then(response => response.json())
            .then(data => {
              console.log(data);
              if (data.success) {
                navigate('/users-list');
              } else {
                // Обработка ошибки
              }
            })
            
            console.log(response);
        } catch (error) {
            console.error("There was an error sending the data", error);
        }
    };
  
    return (
        <Stack>
            <h2 className='header'>User EDIT</h2>
            <div className='inputs-wrapper'>
                <TextField id="name" label="NAME" variant="outlined" required onChange={(e) => setName(e.target.value)} />
            </div>
            <div className='inputs-wrapper'>
                <TextField multiline id="email" label="EMAIL" variant="outlined" onChange={(e) => setEmail(e.target.value)} />
            </div>
            <div className='inputs-wrapper'>
                <TextField multiline id="password" label="PASSWORD" variant="outlined" onChange={(e) => setPassword(e.target.value)} />
            </div>
            <div className='inputs-wrapper'>
                <FormControlLabel control={<Checkbox checked={isAdmin} onChange={() => setIsAdmin(!isAdmin)} />} label="Admin" />
            </div>
            <div className='inputs-wrapper'>
                <Button variant="outlined" onClick={handleSubmit}>save</Button>
            </div>
        </Stack>
    );
}


