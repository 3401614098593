import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import './ServersErrors.scss'


export default function ServersErrors() {
  const [rowsError, setRowsError] = React.useState([]);

  const fetchData = async () => {
    try {

      // Fetch errors
      const responseErrors = await fetch('https://smsauth.messagemate.at/asmsc_api/checkAlarm.php');
      if (responseErrors.ok) {
        let dataErrors = await responseErrors.json();
        dataErrors = dataErrors.data.map((row, index) => {
          return { id: index, ...row };
        });

        setRowsError(dataErrors); 

      } else {
        console.error('Failed to fetch errors:', responseErrors.statusText);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  React.useEffect(() => {
    fetchData();
    const interval = setInterval(fetchData, 5 * 60 * 1000);

    return () => clearInterval(interval);
  }, []);

  const getRowClassName = (params) => {
    const isCritical = params.row.AlarmCritical === 1;
  
    return isCritical ? 'row-highlight-critical' : 'row-highlight-normal';
  };

  const columnsError = [
    {
      field: 'AlarmTime',
      headerName: 'TimeStamp',
      width: 240,
      renderCell: (params) => {
        const dateTime = params.row.AlarmTime.date;
        const dateWithoutMilliseconds = dateTime.split('.')[0];
        return dateWithoutMilliseconds;
      },
    },
    { 
        field: 'AlarmCritical', 
        headerName: 'Level', 
        width: 150,
        renderCell: (params) => {
            if (params.row.AlarmCritical === 1) {
                return <div>Critical</div>
            } else {
                return <div>Normal</div>
            }
        }
    },
    { field: 'MccMnc', headerName: 'MccMnc', width: 100 },
    { field: 'AlarmName', headerName: 'Alarm Type', width: 150 },
    { field: 'AlarmText', headerName: 'Details', width: 440 },
  ];

  return (
    <div>
      <h2 style={{ marginBottom: '15px'}}>ASMSC ALARMS</h2>
      <div style={{ height: 631, width: '100%' }}>
        <DataGrid
          rows={rowsError}
          columns={columnsError}
          getRowClassName={getRowClassName}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          pageSizeOptions={[10, 20, 50]}
        />
      </div>
    </div>

  );
}





