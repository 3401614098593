import * as React from 'react';
// import { Link } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import { useLocation } from 'react-router-dom';


export default function Worksheet() {


    const [rows, setRows] = React.useState([]);
    const location = useLocation();
    

      React.useEffect(() => {
        const getApiUrl = () => {
            switch (location.pathname) {
                case '/worksheet-todo':
                    return 'https://smsadmin.schrego.at/functions/csv2json.php?name=todo';
                case '/worksheet-special':
                    return 'https://smsadmin.schrego.at/functions/csv2json.php?name=special';
                case '/worksheet-applist':
                    return 'https://smsadmin.schrego.at/functions/csv2json.php?name=applist';
                case '/worksheet-password_recovery':
                    return 'https://smsadmin.schrego.at/functions/csv2json.php?name=password_recovery';
                default:
                    return 'https://smsadmin.schrego.at/functions/csv2json.php?name=progress';
            }
        };

        const fetchData = async () => {
            const apiUrl = getApiUrl();
            try {
                const response = await fetch(apiUrl);
                if (response.ok) {
                    const data = await response.json();
                    const transformedData = data.map((item, index) => ({
                        id: index + 1,
                        name: item['Name'],
                        sid: item['SID'],
                        url: item['URL'],
                        function: item['function'],
                        loginPossible: item['login possible'],
                        county: item['county'],
                        iso2: item['Currrent Iso2'],
                        status: item['Status'],
                        serverStatus: item['Server Status'],
                        remark: item['Remark']
                    }));
                    setRows(transformedData);
                    console.log(data)
                } else {
                    console.error('Failed to fetch data:', response.statusText);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
  
        fetchData();
      }, [location.pathname]);
    

  
    const columns = [
        { field: 'name', headerName: 'Name', width: 150 },
        { field: 'sid', headerName: 'SID', width: 150 },
        { field: 'url', headerName: 'URL', width: 250 },
        { field: 'function', headerName: 'Function', width: 100 },
        { field: 'loginPossible', headerName: 'Login Possible?', width: 100 },
        { field: 'county', headerName: 'County', width: 100 },
        { field: 'iso2', headerName: 'Current ISO2', width: 50 },
        { field: 'status', headerName: 'Status', width: 100 },
        { field: 'serverStatus', headerName: 'Server Status', width: 100 },
        { field: 'remark', headerName: 'Remark', width: 300 },
    ];
  
  
    return (
        <div style={{ height: 631, width: '100%' }}>
          <DataGrid
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
              },
            }}
            pageSizeOptions={[10, 20, 50]}
          />
        </div>
      );
  };