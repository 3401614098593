import * as React from 'react';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import LogoutButton from '../logoutButton/LogoutButton';
import Cookies from 'js-cookie';

export default function NavigationMenu({ onLogout }) {

    const user = Cookies.get('username');
    const role = Cookies.get('role');


    return (
        <nav className='main-nav'>
        {role === 'admin' && (
            <>
                <Button component={Link} to="/admin">
                    Admin
                </Button>
            </>
        )}
        <div className="logout-button-wrapper">
            <span className="username"><strong>{user}</strong></span>
            <LogoutButton onLogout={onLogout} />
        </div>
        </nav>
    );
}
