import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Link } from 'react-router-dom';
import LogoutButton from '../logoutButton/LogoutButton';
import Cookies from 'js-cookie';
import './NavigationMenuP2A.scss'

export default function NavigationMenu({ onLogout }) {

    const user = Cookies.get('username');

    const [anchorEl1, setAnchorEl1] = React.useState(null);
    const [anchorEl2, setAnchorEl2] = React.useState(null);
    const [anchorEl3, setAnchorEl3] = React.useState(null);
    const [anchorEl4, setAnchorEl4] = React.useState(null);
    const [anchorEl5, setAnchorEl5] = React.useState(null);
    const [anchorEl6, setAnchorEl6] = React.useState(null);

    const open1 = Boolean(anchorEl1);
    const open2 = Boolean(anchorEl2);
    const open3 = Boolean(anchorEl3);
    const open4 = Boolean(anchorEl4);
    const open5 = Boolean(anchorEl5);
    const open6 = Boolean(anchorEl6);


    const handleClick1 = (event) => {
        setAnchorEl1(event.currentTarget);
    };

    const handleClick2 = (event) => {
        setAnchorEl2(event.currentTarget);
    };

    const handleClick3 = (event) => {
        setAnchorEl3(event.currentTarget);
    };

    const handleClick4 = (event) => {
        setAnchorEl4(event.currentTarget);
    }

    const handleClick5 = (event) => {
        setAnchorEl5(event.currentTarget);
    }

    const handleClick6 = (event) => {
        setAnchorEl6(event.currentTarget);
    }


    const handleClose = () => {
        setAnchorEl1(null);
        setAnchorEl2(null);
        setAnchorEl3(null);
        setAnchorEl4(null);
        setAnchorEl5(null);
        setAnchorEl6(null);
    };

    return (
        <nav className='main-nav'>
        <Button component={Link} to="/index">
            Home
        </Button>
        <Button component={Link} to="/p2a">
            Dashboard
        </Button>
        <Button
            id="basic-button-1"
            aria-controls={open1 ? 'basic-menu-1' : undefined}
            aria-haspopup="true"
            aria-expanded={open1 ? 'true' : undefined}
            onClick={handleClick1}
        >
            Receiver
        </Button>
        <Menu
            id="basic-menu-1"
            anchorEl={anchorEl1}
            open={open1}
            onClose={handleClose}
            MenuListProps={{
            'aria-labelledby': 'basic-button-1',
            }}
        >
            <MenuItem component={Link} to="/p2a/receiver" onClick={handleClose}>List</MenuItem>
            <MenuItem component={Link} to="/p2a/receiver-add" onClick={handleClose}>Add</MenuItem>
            <MenuItem component={Link} to="/p2a/receiver-import" onClick={handleClose}>Import</MenuItem>
        </Menu>
        <Button
            id="basic-button-3"
            aria-controls={open3 ? 'basic-menu-3' : undefined}
            aria-haspopup="true"
            aria-expanded={open3 ? 'true' : undefined}
            onClick={handleClick3}
        >
            Groups
        </Button>
        <Menu
            id="basic-menu-3"
            anchorEl={anchorEl3}
            open={open3}
            onClose={handleClose}
            MenuListProps={{
            'aria-labelledby': 'basic-button-3',
            }}
        >
            <MenuItem component={Link} to="/p2a/groups-list" onClick={handleClose}>List</MenuItem>
            <MenuItem component={Link} to="/p2a/groups-add" onClick={handleClose}>Add</MenuItem>
        </Menu>
        <Button
            id="basic-button-2"
            aria-controls={open2 ? 'basic-menu-2' : undefined}
            aria-haspopup="true"
            aria-expanded={open2 ? 'true' : undefined}
            onClick={handleClick2}
        >
            Send
        </Button>
        <Menu
            id="basic-menu-2"
            anchorEl={anchorEl2}
            open={open2}
            onClose={handleClose}
            MenuListProps={{
            'aria-labelledby': 'basic-button-2',
            }}
        >
            <MenuItem component={Link} to="/p2a/send-goip" onClick={handleClose}>GoIP QD</MenuItem>
            <MenuItem component={Link} to="/p2a/send-direct" onClick={handleClose}>GoIP Bulk</MenuItem>
            <MenuItem component={Link} to="/p2a/send-campaign" onClick={handleClose}>Campaign</MenuItem>
        </Menu>
        <Button
            id="basic-button-6"
            aria-controls={open6 ? 'basic-menu-6' : undefined}
            aria-haspopup="true"
            aria-expanded={open6 ? 'true' : undefined}
            onClick={handleClick6}
        >
            Campaigns
        </Button>
        <Menu
            id="basic-menu-6"
            anchorEl={anchorEl6}
            open={open6}
            onClose={handleClose}
            MenuListProps={{
            'aria-labelledby': 'basic-button-6',
            }}
        >
            <MenuItem component={Link} to="/p2a/running-campaigns" onClick={handleClose}>Running Campaigns</MenuItem>
        </Menu>
        <Button component={Link} to="/p2a/reports">
            Reports
        </Button>
        <Button
            id="basic-button-4"
            aria-controls={open4 ? 'basic-menu-2' : undefined}
            aria-haspopup="true"
            aria-expanded={open4 ? 'true' : undefined}
            onClick={handleClick4}
        >
            SMS Server
        </Button>
        <Menu
            id="basic-menu-4"
            anchorEl={anchorEl4}
            open={open4}
            onClose={handleClose}
            MenuListProps={{
            'aria-labelledby': 'basic-button-4',
            }}
        >
            <MenuItem
            onClick={() => {
                handleClose();
                window.open('http://10.20.30.15/goip/', '_blank');
            }}
            >Administration
            </MenuItem>
            <MenuItem component={Link} to="/p2a/sms-inbox" onClick={handleClose}>SMS Inbox</MenuItem>
        </Menu>
        <Button
            id="basic-button-5"
            aria-controls={open5 ? 'basic-menu-5' : undefined}
            aria-haspopup="true"
            aria-expanded={open5 ? 'true' : undefined}
            onClick={handleClick5}
        >
            Sim Bank
        </Button>
        <Menu
            id="basic-menu-5"
            anchorEl={anchorEl5}
            open={open5}
            onClose={handleClose}
            MenuListProps={{
            'aria-labelledby': 'basic-button-5',
            }}
        >
            <MenuItem
            onClick={() => {
                handleClose();
                window.open('http://10.20.30.15/smb_scheduler/', '_blank');
            }}
            >Administration
            </MenuItem>
            <MenuItem component={Link} to="/p2a/goip-channel-config" onClick={handleClose}>GoIp Channel Config</MenuItem>
        </Menu>
        <div className="logout-button-wrapper">
            <span className="username"><strong>{user}</strong></span>
            <LogoutButton onLogout={onLogout} />
        </div>
        </nav>
    );
}
