import React, { useState, useEffect } from 'react';
import { FormControl, InputLabel, Select, MenuItem, FormHelperText } from '@mui/material';


const GoipIdDropdown = ({ selectedGoipId, providerId, onChange, error, helperText }) => {
    const [goipIds, setGoipIds] = useState([]);
    const [selectedId, setSelectedId] = useState('');

    useEffect(() => {
        const fetchIds = async () => {
        try {
            const response = await fetch(`https://smsauth.messagemate.at/goip_api/listGoips.php?id=${providerId}`);
            const data = await response.json();
            if (data && data.data) {
            setGoipIds(data.data);
            }
        } catch (error) {
            console.error('Error while fetching data:', error);
        }
        };

        if (providerId) {
        fetchIds();
        }
    }, [providerId]);

    useEffect(() => {
        if (selectedGoipId) {
        setSelectedId(selectedGoipId);
        }
    }, [selectedGoipId]);

    const handleChange = (event) => {
        setSelectedId(event.target.value);
        onChange(event.target.value);
    };

    return (
        <FormControl sx={{ minWidth: 232 }} error={error}>
        <InputLabel id="id-select-label">Goip ID</InputLabel>
        <Select
            labelId="id-select-label"
            id="id-select"
            value={selectedId}
            label="goipid"
            onChange={handleChange}
        >
            <MenuItem key="-1" value="-1">
            Random
            </MenuItem>
            {goipIds.map((goipId) => (
            <MenuItem key={goipId.goipid} value={goipId.goipid}>
                {goipId.name} ({goipId.carrier}) → {goipId.simgroup}
            </MenuItem>
            ))}
        </Select>
        {error && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
    );
};

export default GoipIdDropdown;