import React, { useState, useEffect } from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

const SendDirectIdDbDropdown = ({ onIdsSelected, selectedValue }) => {
  const [sendDirectIDs, setSendDirectIDs] = useState([]);
  const [selectedSendTo, setSelectedSendTo] = useState('');

    useEffect(() => {
        const fetchIds = async () => {
        try {
            const response = await fetch('https://smsauth.messagemate.at/goip_api/listReceiverIDs.php');
            const data = await response.json();
            if (data && data.data) {
                setSendDirectIDs(data.data);
            }
        } catch (error) {
            console.error('Error while fetching data:', error);
        }
        };

        fetchIds();
    }, []);

    useEffect(() => {
        setSelectedSendTo(selectedValue);
    }, [selectedValue]);

    const handleChange = async (event) => {
        const selectedId = event.target.value;
        setSelectedSendTo(selectedId);

        try {
        const response = await fetch(`https://smsauth.messagemate.at/goip_api/listReceiverByID.php?id=${selectedId}`);
        const data = await response.json();
        if (data && data.data) {
            const phoneNumbers = data.data.map(item => item.number);
            onIdsSelected(phoneNumbers);
        }
        } catch (error) {
        console.error('Error while fetching numbers:', error);
        }
    };

    return (
        <FormControl sx={{ width: 234 }}>
        <InputLabel id="sendto-select-label">by ID</InputLabel>
        <Select
            labelId="sendto-select-label"
            id="sendto-select"
            value={selectedSendTo}
            label="sendto"
            onChange={handleChange}
        >
            {sendDirectIDs.map((DbIds) => (
            <MenuItem key={DbIds.no} value={DbIds.no}>
                {DbIds.no}
            </MenuItem>
            ))}
        </Select>
        </FormControl>
    );
};

export default SendDirectIdDbDropdown;