import * as React from 'react';
import { Link } from 'react-router-dom';

import './Index.scss';

export default function Index() {
    return(
        <div className='main-choose'>
            <div className='main-column'>
                <Link to="/p2a" className='main-column__link'>
                    <img src="/image/p2a.png" alt="" className="main-column__image" />
                    <h2>P2A</h2>
                    <h3>Roaming GoIp</h3>
                </Link>
            </div>
            <div className='main-column'>
                <Link to="/dashboard" className='main-column__link'>
                    <img src="/image/a2p.png" alt="" className="main-column__image" />
                    <h2>A2P</h2>
                    <h3>2-way sms verification</h3>
                </Link>
            </div>
            <div className='main-column'>
                <Link to="/p2p" className='main-column__link'>
                    <img src="/image/p2p.png" alt="" className="main-column__image" />
                    <h2>P2P</h2>
                    <h3>ASMSC</h3>
                </Link>
            </div>
        </div>
    );
}
