import * as React from 'react';
import Switch from '@mui/material/Switch';

export default function SwitchControlled({ isChecked, onToggle, disabled }) {
    const [checked, setChecked] = React.useState(isChecked);
  
    React.useEffect(() => {
        setChecked(isChecked);
    }, [isChecked]);
  
    const handleChange = (event) => {
        const newChecked = event.target.checked;
        onToggle(newChecked);
        setChecked(newChecked);
    };
  
    return (
        <Switch
            checked={checked}
            onChange={handleChange}
            disabled={disabled}
            inputProps={{ 'aria-label': 'controlled' }}
        />
    );
}