import React, { useState, useEffect } from 'react';
import { FormControl, InputLabel, Select, MenuItem, FormHelperText } from '@mui/material';

const GoipSendtoDropdown = ({ onChange, error, helperText }) => {
    const [sendTo, setSendTo] = useState([]);
    const [selectedSendTo, setSelectedSendTo] = useState('');

  useEffect(() => {
        const fetchIds = async () => {
        try {
            const response = await fetch('https://smsauth.messagemate.at/goip_api/qdListSendTo.php');
            const data = await response.json();
            if (data && Array.isArray(data.data)) {
            const cleanedSendTo = data.data.map(item => item.replace(/x/g, ''));
            setSendTo(cleanedSendTo);
            }
        } catch (error) {
            console.error('Error while fetching data:', error);
        }
        };

        fetchIds();
    }, []);

    const handleChange = (event) => {
        const valueWithoutX = event.target.value.replace(/x/g, '');
        setSelectedSendTo(event.target.value);
        onChange(valueWithoutX);
    };

    return (
        <FormControl sx={{ width: 234 }} error={error}>
        <InputLabel id="sendto-select-label">Send To</InputLabel>
        <Select
            labelId="sendto-select-label"
            id="sendto-select"
            value={selectedSendTo}
            label="sendto"
            onChange={handleChange}
        >
            {sendTo.map((sendToItem) => (
            <MenuItem key={sendToItem} value={sendToItem + 'xx'}>
                {sendToItem + 'xx'}
            </MenuItem>
            ))}
        </Select>
        {error && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
    );
};

export default GoipSendtoDropdown;