import React, { useEffect, useState } from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import './SmsCounter.scss';

export default function SmsCounter() {
    const [data, setData] = useState({});
    const [dataPopup, setDataPopup] = useState({
        today: [],
        yesterday: [],
        week: [],
        month: []
    });
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentTimespan, setCurrentTimespan] = useState('');

    const fetchData = () => {
        fetch('https://smsauth.messagemate.at/api/countSMS.php')
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                if (data['errorcode'] === "200") {
                    setData(data.data);
                } else {
                    setError(data['error']);
                }
                setIsLoading(false);
            })
            .catch(error => {
                setError(error.message);
                setIsLoading(false);
            });
    };

    const fetchPopupData = () => {
        fetch('https://smsauth.messagemate.at/api/countSMSperCountry.php')
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                if (data['errorcode'] === "200") {
                    const groupedData = {
                        today: [],
                        yesterday: [],
                        week: [],
                        month: []
                    };
                    data.data.forEach(item => {
                        const timespan = item.timespan.toLowerCase();
                        groupedData[timespan].push(item);
                    });
                    setDataPopup(groupedData);
                } else {
                    setError(data['error']);
                }
            })
            .catch(error => {
                setError(error.message);
            });
    };

    useEffect(() => {
        fetchData();
        fetchPopupData();
        const interval = setInterval(() => {
            fetchData();
            fetchPopupData();
        }, 5 * 60 * 1000);

        return () => clearInterval(interval);
    }, []);

    const handlePopoverOpen = (event, timespan) => {
        setCurrentTimespan(timespan);
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
        setCurrentTimespan('');
    };

    const isPopoverOpen = Boolean(anchorEl);
    const popoverId = isPopoverOpen ? 'simple-popover' : undefined;

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div className="counter__wrapper">
            {['today', 'yesterday', 'week', 'month'].map((timespan, index) => (
                <div className="counter__box" key={index}
                    onMouseEnter={(event) => handlePopoverOpen(event, timespan)}
                    onMouseLeave={handlePopoverClose}>
                    <span className="counter__text">{timespan.toUpperCase()}</span>
                    <span className="counter__number">{data[timespan] || 0}</span>
                </div>
            ))}
            <Popover
                id={popoverId}
                open={isPopoverOpen}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                sx={{
                    pointerEvents: 'none',
                }}
                disableRestoreFocus
            >
                <Typography sx={{ p: 2 }}>
                    {dataPopup[currentTimespan] && dataPopup[currentTimespan].length > 0 ? (
                        dataPopup[currentTimespan].map((item, index) => (
                            <React.Fragment key={index}>
                                <div>
                                    <img src={`/image/flags/${item.iso2.toLowerCase()}.png`} alt={`${item.country} flag`} style={{ width: 24, marginRight: 8 }} />
                                    {item.country}: {item.received}
                                </div>
                                {index < dataPopup[currentTimespan].length - 1 && <Divider sx={{ my: 1, backgroundColor: '#000' }} />}
                            </React.Fragment>
                        ))
                    ) : (
                        <div>No data available for {currentTimespan}</div>
                    )}
                </Typography>
            </Popover>
        </div>
    );
}
